<template>
  <section>
    <v-card v-if="showWarning" class="rounded-0 elevation-0 fill-height transparent">
      <v-app-bar dark color="primary" class="elevation-0">
        <v-toolbar-title>Docline</v-toolbar-title>
      </v-app-bar>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <h1>{{ $t('2fa.storeRedirectWarningTitle') }}</h1>
            <p>{{ $t('2fa.storeRedirectWarning') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div v-else class="full-screen-loader">
      <v-progress-circular indeterminate color="primary" />
    </div>
  </section>
</template>

<script>
import { GOOGLE_AUTHENTICATOR_PLAY_STORE_LINK, GOOGLE_AUTHENTICATOR_APP_STORE_LINK } from '@/config/constants';
export default {
  name: 'GoogleAuthenticatorRedirect',

  data() {
    return {
      userAgent: null,
      stores: {
        android: GOOGLE_AUTHENTICATOR_PLAY_STORE_LINK,
        ios: GOOGLE_AUTHENTICATOR_APP_STORE_LINK,
      },
      showWarning: false,
    };
  },

  created() {
    this.setUserAgent();
    this.redirect();
  },

  methods: {
    setUserAgent() {
      const { isAndroid, isIOS } = this.$device;
      this.userAgent = isAndroid ? 'android' : isIOS ? 'ios' : null;
    },

    redirect() {
      if (!this.userAgent) {
        this.showWarning = true;
        return;
      }

      window.location.replace(this.stores[this.userAgent]);
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
